
import React from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
 return (
   <main className="main">
     <Helmet>
       <meta charSet="utf-8" />
       <title>Privacy Policy - Capago</title>
       <meta
         name="description"
         content="Read the privacy policy of Capago. Learn about how we collect, process, and protect your personal data, as well as your rights regarding this data."
       />
       <meta
         name="keywords"
         content="privacy policy, data protection, personal information, Capago, user rights"
       />
     </Helmet>
     <section className="terms-group-section">
       <div className="terms-data-img">
         <div className="terms-details">
           <div>
             <h1 className="condition-h1">{t("privacy.description")}</h1>
             <p className="condition-date">{t("privacy.data")}</p>
           </div>
           <div>
             <div className="condition-line"></div>
             <p className="condition-pera">{t("privacy.para")}</p>
           </div>
         </div>
       </div>
     </section>
     <section>
       <div className="innovation-radius">
         <div className="container">
           <div className="terms-details-values">
             <div className="terms-notes">
               <h2 className="term-heading">{t("privacy.article1title")}</h2>
               <p className="terms-pera">{t("privacy.article1para")}</p>
               <ul className="terms-ul">
                 <li className="terms-li">{t("privacy.article1Li1")}</li>
                 <li className="terms-li">{t("privacy.article1Li2")}</li>
                 <li className="terms-li">{t("privacy.article1Li3")}</li>
                 <li className="terms-li">{t("privacy.article1Li4")}</li>
                 <li className="terms-li">{t("privacy.article1Li5")}</li>
                 <li className="terms-li">{t("privacy.article1Li6")}</li>
               </ul>
               <p className="terms-pera">{t("privacy.article1para2")}</p>
             </div>
             <div className="terms-notes">
               <h2 className="term-heading">{t("privacy.article2title")}</h2>
               <p className="terms-pera mb-0">{t("privacy.article2para")}</p>
               <ul className="terms-ul">
                 <li className="artical-li">
                   Article : <br /> <span>{t("privacy.article2Li1")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   {t("privacy.article2Li2")}
                   <br /> <span>{t("privacy.article2Li3")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   {t("privacy.article2Li4")} <br />{" "}
                   <span>{t("privacy.article2Li5")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   Client : <br /> <span>{t("privacy.article2Li6")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   Cookie : <br /> <span>{t("privacy.article2Li7")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   {t("privacy.article2Li8")} <br />{" "}
                   <span> {t("privacy.article2Li9")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   {t("privacy.article2Li10")}
                   <br /> <span> {t("privacy.article2Li11")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   {t("privacy.article2Li12")} <br />{" "}
                   <span>{t("privacy.article2Li13")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   {t("privacy.article2Li14")} <br />{" "}
                   <span>{t("privacy.article2Li15")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   {t("privacy.article2Li16")}
                   <br /> <span>{t("privacy.article2Li17")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   {t("privacy.article2Li18")}
                   <br /> <span>{t("privacy.article2Li19")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   {t("privacy.article2Li20")}
                   <br /> <span>{t("privacy.article2Li21")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   {t("privacy.article2Li22")}
                   <br /> <span>{t("privacy.article2Li23")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   {t("privacy.article2Li24")}
                   <br /> <span>{t("privacy.article2Li25")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   {t("privacy.article2Li26")}
                   <br /> <span>{t("privacy.article2Li27")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   {t("privacy.article2Li28")}
                   <br /> <span>{t("privacy.article2Li29")}</span>{" "}
                 </li>
                 <li className="artical-li">
                   {t("privacy.article2Li30")}
                   <br /> <span>{t("privacy.article2Li31")}</span>{" "}
                 </li>
               </ul>
               <p className="terms-pera">{t("privacy.article2para2")}</p>
               <p className="terms-pera">{t("privacy.article2para3")}</p>
               <p className="terms-pera">{t("privacy.article2para4")}</p>
               <p className="terms-pera">{t("privacy.article2para5")}</p>
             </div>
             <div className="terms-notes">
               <h2 className="term-heading">{t("privacy.article3title")}</h2>
               <p className="terms-pera mb-0">{t("privacy.article3para")}</p>
               <ul className="terms-ul">
                 <li className="terms-li">{t("privacy.article3Li1")}</li>
                 <li className="terms-li">{t("privacy.article3Li2")}</li>
                 <li className="terms-li">{t("privacy.article3Li3")}</li>
                 <li className="terms-li">{t("privacy.article3Li4")}</li>
                 <li className="terms-li">{t("privacy.article3Li5")}</li>
                 <li className="terms-li">{t("privacy.article3Li6")}</li>
               </ul>
               <p className="terms-pera mb-0">{t("privacy.article3Li7")}</p>
               <ul className="terms-ul">
                 <li className="terms-li">{t("privacy.article3Li8")}</li>
                 <li className="terms-li">{t("privacy.article3Li9")}</li>
                 <li className="terms-li">{t("privacy.article3Li10")}</li>
                 <li className="terms-li">{t("privacy.article3Li11")}</li>
                 <li className="terms-li">{t("privacy.article3Li12")}</li>
                 <li className="terms-li">{t("privacy.article3Li13")}</li>
               </ul>
             </div>
             <div className="terms-notes">
               <h2 className="term-heading">{t("privacy.article4title")}</h2>
               <h3 className="term-sub-text">{t("privacy.article4heading")}</h3>
               <p className="terms-pera">
                 {" "}
                 <span> {t("privacy.article4para1")} </span>
                 <br />
                 {t("privacy.article4para2")}
               </p>
               <p className="terms-pera">
                 {t("privacy.article4para3")}
                 <br />
                 {t("privacy.article4para4")}
               </p>
               <p className="terms-pera">{t("privacy.article4para5")}</p>
               <p className="terms-pera">{t("privacy.article4para6")}</p>
               <p className="terms-pera">{t("privacy.article4para7")}</p>
               <p className="terms-pera">{t("privacy.article4para8")}</p>
               <p className="terms-pera">{t("privacy.article4para9")}</p>
               <p className="terms-pera">
                 {" "}
                 <span> {t("privacy.article4para10")}</span>
                 <br />
                 {t("privacy.article4para11")}
                 <br />
                 {t("privacy.article4para12")}
               </p>
               <ul className="terms-ul">
                 <li className="terms-li">{t("privacy.article4Li1")}</li>
                 <li className="terms-li">{t("privacy.article4Li2")}</li>
                 <li className="terms-li">{t("privacy.article4Li3")}</li>
               </ul>
               <p className="terms-pera">{t("privacy.article4para13")}</p>
               <p className="terms-pera">
                 {" "}
                 <span> {t("privacy.article4para14")} </span>
                 <br />
                 {t("privacy.article4para15")}
                 <br />
                 {t("privacy.article4para16")}
               </p>
               <p className="terms-pera">
                 {" "}
                 <span> {t("privacy.article4para17")} </span>
                 <br />
                 {t("privacy.article4para18")}
               </p>
               <p className="terms-pera"> {t("privacy.article4para19")}</p>
               <p className="terms-pera">
                 {" "}
                 <span> {t("privacy.article4para20")}</span>
                 <br />
                 {t("privacy.article4para21")}
                 <br />
                 {t("privacy.article4para22")}
               </p>
               <p className="terms-pera"> {t("privacy.article4para23")}</p>
               <p className="terms-pera"> {t("privacy.article4para24")}</p>
             </div>
             <div className="terms-notes">
               <h2 className="term-heading">{t("privacy.article4_2title")}</h2>
               <p className="terms-pera">{t("privacy.article4_2para1")}</p>
               <p className="terms-pera">{t("privacy.article4_2para2")}</p>
               <ul className="terms-ul">
                 <li className="terms-li">{t("privacy.article4_2Li1")}</li>
                 <li className="terms-li">{t("privacy.article4_2Li2")}</li>
                 <li className="terms-li">{t("privacy.article4_2Li3")}</li>
                 <li className="terms-li">{t("privacy.article4_2Li4")}</li>
               </ul>
               <p className="terms-pera">{t("privacy.article4_2para3")}</p>
               <p className="terms-pera">{t("privacy.article4_2para4")}</p>
               <p className="terms-pera">{t("privacy.article4_2para5")}</p>
               <p className="terms-pera">
                 <span>{t("privacy.article4_2para6")}</span>
                 <br />
                 {t("privacy.article4_2para7")}
               </p>
               <p className="terms-pera">{t("privacy.article4_2para8")}</p>
               <p className="terms-pera">{t("privacy.article4_2para9")}</p>
               <p className="terms-pera">
                 <span>{t("privacy.article4_2para10")}</span>
                 <br />
                 {t("privacy.article4_2para11")}
               </p>
             </div>

             <div className="terms-notes">
               <h2 className="term-heading">{t("privacy.article4_3title")}</h2>
               <h3 className="term-sub-heading">
                 {t("privacy.article4_3para1")}
                 <br />
                 OVH <br />
                 {t("privacy.article4_3para2")}
               </h3>
             </div>
             <div className="terms-notes">
               <h2 className="term-heading">{t("privacy.article_4_4")}</h2>
               <p className="terms-pera">
                 <span>{t("privacy.section_4_4_1")}</span>
                 <br />
                 {t("privacy.section_4_4_1_text")}
               </p>
               <p className="terms-pera">
                 <span>{t("privacy.section_4_4_2")}</span>
                 <br />
                 {t("privacy.section_4_4_2_text")}
               </p>
               <p className="terms-pera">{t("privacy.article4_4data1")}</p>
               <p className="terms-pera">
                 <span>{t("privacy.section_4_4_3")}</span>
                 <br />
                 {t("privacy.section_4_4_3_text")}
               </p>
               <p className="terms-pera">{t("privacy.article4_4data2")}</p>
               <p className="terms-pera">
                 <span>{t("privacy.section_4_4_4")}</span>
                 <br />
                 {t("privacy.section_4_4_4_text")}
               </p>
               <p className="terms-pera">{t("privacy.service_providers")}</p>
               <ul className="terms-ul">
                 <li className="terms-li">{t("privacy.provider_list1")}</li>
                 <li className="terms-li">{t("privacy.provider_list2")}</li>
               </ul>
               <p className="terms-pera">{t("privacy.article4_4data3")}</p>
               <ul className="terms-ul">
                 <li className="terms-li">{t("privacy.article4_4data4")}</li>
                 <li className="terms-li">{t("privacy.article4_4data5")}</li>
                 <li className="terms-li">{t("privacy.article4_4data6")}</li>
                 <li className="terms-li">{t("privacy.article4_4data7")}</li>
               </ul>
               <p className="terms-pera">{t("privacy.section_4_4_5_text")}</p>
               <ul className="terms-ul">
                 <li className="terms-li">{t("privacy.data_security1")}</li>
                 <li className="terms-li">{t("privacy.data_security2")}</li>
                 <li className="terms-li">{t("privacy.data_security3")}</li>
                 <li className="terms-li">{t("privacy.data_security4")}</li>
               </ul>
               <p className="terms-pera">{t("privacy.acceptance_statement")}</p>
             </div>
             <div className="terms-notes">
               <h2 className="term-heading">{t("privacy.article4_5title")}</h2>
               <p className="terms-pera">{t("privacy.article4_5para1")}</p>
             </div>
             <div className="terms-notes">
               <h2 className="term-heading">{t("privacy.article4_6title")}</h2>
               <p className="terms-pera">{t("privacy.article4_6para1")}</p>
               <p className="terms-pera">{t("privacy.article4_6para2")}</p>
               <p className="terms-pera">{t("privacy.article4_6para3")}</p>
               <p className="terms-pera">{t("privacy.article4_6para4")}</p>
               <p className="terms-pera">{t("privacy.article4_6para5")}</p>
               <p className="terms-pera">{t("privacy.article4_6para6")}</p>
             </div>
             <div className="terms-notes">
               <h2 className="term-heading">{t("privacy.article_5")}</h2>
               <h2 className="term-heading">{t("privacy.article_5_1")}</h2>
               <p className="terms-pera">{t("privacy.terms_pera_1")}</p>
               <p className="terms-pera">{t("privacy.contact_1")}</p>
               <ul className="terms-ul">
                 <li className="terms-li">{t("privacy.contact_2")}</li>
                 <li className="terms-li">{t("privacy.contact_3")}</li>
                 <li className="terms-li">{t("privacy.contact_4")}</li>
               </ul>
             </div>
             <div className="terms-notes">
               <h2 className="term-heading">{t("privacy.article_5_2")}</h2>
               <p className="terms-pera mb-10">{t("privacy.terms_pera_2")}</p>
               <h2 className="term-heading">{t("privacy.article_6")}</h2>
               <p className="terms-pera">{t("privacy.terms_pera_3")}</p>
               <ul className="terms-ul">
                 <li className="terms-li">{t("privacy.rights_1")}</li>
                 <li className="terms-li">{t("privacy.rights_2")}</li>
                 <li className="terms-li">{t("privacy.rights_3")}</li>
                 <li className="terms-li">{t("privacy.rights_4")}</li>
                 <li className="terms-li">{t("privacy.rights_5")}</li>
                 <li className="terms-li">{t("privacy.rights_6")}</li>
               </ul>
               <p className="terms-pera">{t("privacy.terms_pera_4")}</p>
               <p className="terms-pera">{t("privacy.terms_pera_5")}</p>
               <h2 className="term-heading">{t("privacy.article_7")}</h2>
               <p className="terms-pera">{t("privacy.terms_pera_6")}</p>
               <p className="terms-pera">{t("privacy.terms_pera_7")}</p>
               <p className="terms-pera">{t("privacy.terms_pera_8")}</p>
             </div>
           </div>
         </div>
       </div>
     </section>
   </main>
 );
}

export default PrivacyPolicy
