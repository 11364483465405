import React from "react";
import { Helmet } from "react-helmet";
import CorporateCulture from "../views/talents/CorporateCulture";
import FiguresItems from "../components/talents/FiguresItems";
import Values from "../views/talents/Values";
import Recruitment from "../views/talents/Recruitment";
import Join from "../views/talents/Join";
import Career from "../views/talents/Career";
import SimpleSlider from "../views/talents/Slider2";
import TalentsHero from "../views/talents/Hero";
import { useTranslation } from "react-i18next";

const Talents = () => {
  const { t } = useTranslation();
  return (
    <main className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("title_talents")}</title>
        <meta name="description" content={t("description_talents")} />
        <meta name="keywords" content={t("keywords_talents")} />
      </Helmet>
      <TalentsHero />
      <CorporateCulture />
      <FiguresItems />
      <Values />
      <SimpleSlider />
      <Recruitment />
      <Join />
      <Career />
    </main>
  );
};

export default Talents;
