import React from "react";
import { useTranslation } from "react-i18next";
import img1 from "../../assets/img/user.svg";
import img2 from "../../assets/img/flag.svg";
import img3 from "../../assets/img/boy.svg";
import img4 from "../../assets/img/woman.svg";
import Figures from "../../views/talents/Figures";

const FiguresItems = () => {
  const { t } = useTranslation();

  const services_data = [
    {
      title: t("collaborators_title"),
      image: img1,
      count: "230",
      description: t("collaborators_description"),
    },
    {
      title: t("nationalities_title"),
      image: img2,
      count: "24",
      description: t("nationalities_description"),
    },
    {
      title: t("male_percentage_title"),
      image: img3,
      count: "44%",
      description: t("male_percentage_description"),
    },
    {
      title: t("female_percentage_title"),
      image: img4,
      count: "56%",
      description: t("female_percentage_description"),
    },
  ];

  return <Figures data={services_data} />;
};

export default FiguresItems;
