import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();
  return (
    <main className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("terms.title")}</title>
        <meta name="description" content={t("terms.desc")} />
        <meta name="keywords" content={t("terms.keywords")} />
      </Helmet>
      <section className="terms-group-section">
        <div className="terms-data-img">
          <div className="terms-details">
            <div>
              <h1 className="condition-h1">{t("terms.title1")}</h1>
              <p className="condition-date">{t("terms.p1")}</p>
            </div>
            <div>
              <div className="condition-line"></div>
              <p className="condition-pera">{t("terms.p2")}</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="innovation-radius">
          <div className="container">
            <div className="terms-details-values">
              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article1title")}</h2>
                <p className="terms-pera mb-0">{t("terms.article1para1")}</p>
                <ul className="terms-ul">
                  <li className="artical-li">
                    {t("terms.article1Li1")}
                    <br /> <span>{t("terms.article1Li2")}</span>
                  </li>
                  <li className="artical-li">
                    {t("terms.article1Li3")}
                    <br />
                    <span>{t("terms.article1Li4")}</span>{" "}
                  </li>
                  <li className="artical-li">
                    {t("terms.article1Li5")}
                    <br /> <span>{t("terms.article1Li6")}</span>
                  </li>
                  <li className="artical-li">
                    {t("terms.article1Li7")}
                    <br /> <span>{t("terms.article1Li8")}</span>
                  </li>
                  <li className="artical-li">
                    {t("terms.article1Li9")}
                    <br /> <span>{t("terms.article1Li10")}</span>{" "}
                  </li>
                </ul>
                <p className="terms-pera">{t("terms.article1para2")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article2title")}</h2>
                <p className="terms-pera">{t("terms.article2para1")}</p>
                <p className="terms-pera">{t("terms.article2para2")}</p>
                <p className="terms-pera">{t("terms.article2para3")}</p>
                <p className="terms-pera">{t("terms.article2para4")}</p>
                <p className="terms-pera">{t("terms.article2para5")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article3title")}</h2>
                <p className="terms-pera">{t("terms.article3para1")}</p>
                <ul className="terms-ul">
                  <li className="artical-li">{t("terms.article3Li1")}</li>
                  <li className="artical-li">{t("terms.article3Li2")}</li>
                  <li className="artical-li">{t("terms.article3Li3")}</li>
                  <li className="artical-li">{t("terms.article3Li4")}</li>
                  <li className="artical-li">{t("terms.article3Li5")}</li>
                  <li className="artical-li">{t("terms.article3Li6")}</li>
                  <li className="artical-li">{t("terms.article3Li7")}</li>
                  <li className="artical-li">{t("terms.article3Li8")}</li>
                </ul>
                <p className="terms-pera">{t("terms.article3para2")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article4title")}</h2>
                <p className="terms-pera">{t("terms.article4para1")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article5title")}</h2>
                <p className="terms-pera">{t("terms.article5para1")}</p>
                <p className="terms-pera">{t("terms.article5para2")}</p>
                <p className="terms-pera">{t("terms.article5para3")}</p>
                <p className="terms-pera">{t("terms.article5para4")}</p>
                <h2 className="term-heading">{t("terms.article6title")}</h2>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article6_1title")}</h2>
                <p className="terms-pera">{t("terms.article6_1para1")}</p>
                <p className="terms-pera">{t("terms.article6_1para2")}</p>
                <p className="terms-pera">{t("terms.article6_1para3")} </p>
                <p className="terms-pera">{t("terms.article6_1para4")}</p>
                <p className="terms-pera">{t("terms.article6_1para5")}</p>
                <p className="terms-pera">{t("terms.article6_1para6")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article6_2title")}n</h2>
                <p className="terms-pera">{t("terms.article6_2para1")}</p>
                <p className="terms-pera">{t("terms.article6_2para2")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article6_3title")}</h2>
                <p className="terms-pera">{t("terms.article6_3para1")}</p>
                <p className="terms-pera">{t("terms.article6_3para2")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article7title")}</h2>
                <p className="terms-pera">{t("terms.article7para1")}</p>
                <p className="terms-pera">{t("terms.article7para2")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article8title")}</h2>
                <p className="terms-pera">{t("terms.article8para1")}</p>
                <p className="terms-pera">{t("terms.article8para2")}</p>
                <p className="terms-pera">{t("terms.article8para3")}</p>
                <p className="terms-pera">{t("terms.article8para4")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article9title")}</h2>
                <p className="terms-pera">{t("terms.article9para1")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article10title")}</h2>
                <p className="terms-pera">{t("terms.article10para1")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article11title")}</h2>
                <p className="terms-pera">{t("terms.article11para1")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article12title")}</h2>
                <p className="terms-pera">{t("terms.article12para1")}</p>
              </div>

              <div className="terms-notes">
                <h2 className="term-heading">{t("terms.article13title")}</h2>
                <p className="terms-pera">{t("terms.article13para1")}</p>
                <p className="terms-pera">{t("terms.article13para2")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Terms;
