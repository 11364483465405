import { BrowserRouter, Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import Navbar from "./components/Navbar";
import Groups from "./pages/Groups";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Talents from "./pages/Talents";
import Solutions from "./pages/Solutions";
import Terms from "./pages/Terms";
import CookiePolicy from "./pages/CookiePolicy";
import EmailTemplate from "./pages/EmailTemplate";
import FutureChallenges from "./pages/FutureChallenges";
import ApplicationHub from "./pages/ApplicationHub";
import ViewData from "./views/perspectives/ViewData";
import SalesTerms from "./pages/SalesTerms";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/ScrollToTop";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Navbar />
        {/* <ScrollToTop /> */}
        <div>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/the-group" element={<Groups />}></Route>
            <Route path="/our-solutions" element={<Solutions />}></Route>
            <Route path="/talents" element={<Talents />}></Route>
            <Route path="/contact" element={<Contact />}></Route>

            <Route path="/terms" element={<Terms />}></Route>
            <Route path="/sales-terms" element={<SalesTerms />}></Route>
            <Route path="/cookies" element={<CookiePolicy />}></Route>
            <Route path="/email" element={<EmailTemplate />}></Route>
            <Route path="/challenges" element={<FutureChallenges />}></Route>
            <Route path="/hub" element={<ApplicationHub />}></Route>
            <Route path="/perspectives" element={<ViewData />}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
