import React from "react";
import { useTranslation } from "react-i18next";
import Hero from "../../components/Hero";
import Talents from "../../assets/img/Talents.png";

const TalentsHero = () => {
  const { t } = useTranslation();

  const hero_section_data = {
    category: t("talents_category"),
    image: Talents,
    desc: t("talents_desc"),
    image_desc: t("talents_image_desc"),
  };

  return <Hero {...hero_section_data} />;
};

export default TalentsHero;
