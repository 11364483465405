import React from "react";
import { useTranslation } from "react-i18next";

const Recruitment = () => {
  const { t } = useTranslation();
  return (
    <section>
      <div className="container">
        <div className="recruitment-processes">
          <h2>{t("recruitment.title")}</h2>
          <p>{t("recruitment.intro1")}</p>

          <p> {t("recruitment.intro2")}</p>
        </div>
        <div className="block-list">
          <div className="rp-block">
            <div className="process-block">
              <div className="number">{t("recruitment.steps.number1")}</div>
              <div className="process-title">
                <h4>{t("recruitment.steps.title1")}</h4>
                <p>{t("recruitment.steps.description1")}</p>
              </div>
            </div>
          </div>
          <div className="rp-block">
            <div className="process-block">
              <div className="number">{t("recruitment.steps.number2")}</div>
              <div className="process-title">
                <h4>{t("recruitment.steps.title2")} </h4>
                <p>{t("recruitment.steps.description2")}</p>
              </div>
            </div>
          </div>
          <div className="rp-block">
            <div className="process-block">
              <div className="number">{t("recruitment.steps.number3")}</div>
              <div className="process-title">
                <h4>{t("recruitment.steps.title3")}</h4>
                <p>{t("recruitment.steps.description3")}</p>
              </div>
            </div>
          </div>
          <div className="rp-block">
            <div className="process-block">
              <div className="number">{t("recruitment.steps.number4")}</div>
              <div className="process-title">
                <h4>{t("recruitment.steps.title4")}</h4>
                <p>{t("recruitment.steps.description4")}</p>
              </div>
            </div>
          </div>
          <div className="rp-block">
            <div className="process-block">
              <div className="number">{t("recruitment.steps.number5")}</div>
              <div className="process-title">
                <h4>{t("recruitment.steps.title5")}</h4>
                <p>{t("recruitment.steps.description5")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Recruitment;