import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
const SalesTerms = () => {
  const { t } = useTranslation();
  return (
    <main className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("sales.title")}</title>
        <meta name="description" content={t("sales.description")} />
        <meta name="keywords" content={t("sales.keywords")} />
      </Helmet>
      <section className="terms-group-section">
        <div className="terms-data-img">
          <div className="terms-details">
            <div>
              <h1 className="condition-h1"> {t("sales.title1")}</h1>
              <p className="condition-date">{t("sales.date")}</p>
            </div>
            <div>
              <div className="condition-line"></div>
              <p className="condition-pera">{t("sales.para1")}</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="innovation-radius">
          <div className="container">
            <div className="terms-details-values">
              <div className="terms-notes">
                <h2 className="term-heading">{t("sales.intro")}</h2>
                <p className="terms-pera">{t("sales.introPara")}</p>
                <ul className="terms-ul">
                  <li className="terms-li">{t("sales.introLi1")}</li>
                  <li className="terms-li">{t("sales.introLi2")}</li>
                  <li className="terms-li">{t("sales.introLi3")}</li>
                  <li className="terms-li">{t("sales.introLi4")}</li>
                  <li className="terms-li">{t("sales.introLi5")}</li>
                </ul>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading"> {t("sales.article1")}</h2>
                <p className="terms-pera mb-0">{t("sales.articlePara1")}</p>
                <ul className="terms-ul">
                  <li className="artical-li">
                    {t("sales.articleLi1")} <br />{" "}
                    <span>{t("sales.articleLi2")}</span>{" "}
                  </li>
                  <li className="artical-li">
                    {t("sales.articleLi3")} <br />{" "}
                    <span>{t("sales.articleLi4")}</span>{" "}
                  </li>
                  <li className="artical-li">
                    {t("sales.articleLi5")}
                    <br /> <span>{t("sales.articleLi6")}</span>{" "}
                  </li>
                  <li className="artical-li">
                    {t("sales.articleLi7")} <br />{" "}
                    <span>{t("sales.articleLi8")}</span>{" "}
                  </li>
                  <li className="artical-li">
                    {t("sales.articleLi9")} <br />{" "}
                    <span>{t("sales.articleLi10")}</span>{" "}
                  </li>
                  <li className="artical-li">
                    {t("sales.articleLi11")}
                    <br /> <span> {t("sales.articleLi12")}</span>{" "}
                  </li>
                  <li className="artical-li">
                    {t("sales.articleLi13")}
                    <br /> <span>{t("sales.articleLi14")}</span>{" "}
                  </li>
                  <li className="artical-li">
                    {t("sales.articleLi15")} <br />{" "}
                    <span>{t("sales.articleLi16")}</span>{" "}
                  </li>
                  <li className="artical-li">
                    {t("sales.articleLi17")}
                    <br /> <span>{t("sales.articleLi18")}</span>{" "}
                  </li>
                  <li className="artical-li">
                    {t("sales.articleLi19")}
                    <br /> <span>{t("sales.articleLi20")}</span>{" "}
                  </li>
                  <li className="artical-li">
                    {t("sales.articleLi21")} <br />{" "}
                    <span>{t("sales.articleLi22")}</span>{" "}
                  </li>
                  <li className="artical-li">
                    {t("sales.articleLi23")}
                    <br /> <span>{t("sales.articleLi24")}</span>{" "}
                  </li>
                  <li className="artical-li">
                    {t("sales.articleLi25")}
                    <br /> <span>{t("sales.articleLi26")}</span>{" "}
                  </li>
                </ul>
                <p className="terms-pera">{t("sales.articlePara2")}</p>
                <p className="terms-pera">{t("sales.articlePara3")}</p>
                <p className="terms-pera">{t("sales.articlePara4")}</p>
                <p className="terms-pera">{t("sales.articlePara5")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("sales.article2Title")}</h2>
                <p className="terms-pera mb-0">{t("sales.article2para")}</p>
                <ul className="terms-ul">
                  <li className="terms-li">{t("sales.article2Li1")}</li>
                  <li className="terms-li">{t("sales.article2Li2")}</li>
                  <li className="terms-li">{t("sales.article2Li3")}</li>
                  <li className="terms-li">{t("sales.article2Li4")}</li>
                  <li className="terms-li">{t("sales.article2Li5")}</li>
                  <li className="terms-li">{t("sales.article2Li6")}</li>
                  <li className="terms-li">{t("sales.article2Li7")}</li>
                  <li className="terms-li">{t("sales.article2Li8")}</li>
                  <li className="terms-li">{t("sales.article2Li9")}</li>
                  <li className="terms-li">{t("sales.article2Li10")}</li>
                  <li className="terms-li">{t("sales.article2Li11")}</li>
                </ul>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("sales.article3title")}</h2>
                <p className="terms-pera mb-0">{t("sales.article3para")}</p>
                <p className="terms-pera">{t("sales.article3para2")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("sales.article4title")}</h2>
                <p className="terms-pera">{t("sales.article4para")}</p>
                <p className="terms-pera">{t("sales.article4para2")}</p>
                <p className="terms-pera">{t("sales.article4para3")}</p>
                <p className="terms-pera">{t("sales.article4para4")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">
                  {t("sales.article_5_payment_fees")}
                </h2>
                <h3 className="term-sub-heading">{t("sales.fees_typology")}</h3>
                <p className="terms-pera mb-0">{t("sales.fees_composition")}</p>
                <ul className="terms-ul">
                  <li className="terms-li">
                    {t("sales.visa_fee_description")}
                  </li>
                  <li className="terms-li">
                    {t("sales.basic_service_fee_description")}
                  </li>
                  <li className="terms-li">
                    {t("sales.additional_service_fee_description")}
                  </li>
                </ul>
                <p className="terms-pera">
                  {t("sales.fees_applicable_details")}
                </p>
                <p className="terms-pera">{t("sales.payment_requirement")}</p>
                <h3 className="term-sub-heading">{t("sales.fee_payment")}</h3>
                <p className="terms-pera mb-0">
                  {t("sales.payment_methods_intro")}
                </p>
                <ul className="terms-ul">
                  <li className="terms-li">{t("sales.online_payment")}</li>
                  <li className="terms-li">{t("sales.cash_deposit")}</li>
                  <li className="terms-li">{t("sales.check_payment")}</li>
                  <li className="terms-li">{t("sales.credit_card")}</li>
                  <li className="terms-li">{t("sales.bank_transfer")}</li>
                </ul>
                <p className="terms-pera">
                  {t("sales.available_payment_methods")}
                </p>
                <h3 className="term-sub-heading">{t("sales.prepayment")}</h3>
                <p className="terms-pera">
                  {t("sales.prepayment_requirements")}
                </p>
                <p className="terms-pera">{t("sales.virtual_queue_option")}</p>
                <p className="terms-pera">
                  {t("sales.prepayment_nonrefundable")}
                </p>
                <h3 className="term-sub-heading">
                  {t("sales.special_conditions")}
                </h3>
                <p className="terms-pera">
                  {t("sales.special_conditions_info")}
                </p>
                <h3 className="term-sub-heading">
                  {t("sales.applicable_exchange_rate")}
                </h3>
                <p className="terms-pera">{t("sales.exchange_rate_info")}</p>
                <p className="terms-pera">
                  {t("sales.additional_bank_fees_info")}
                </p>
                <p className="terms-pera">
                  {t("sales.currency_fluctuation_notice")}
                </p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">
                  {t("sales.article_6_communication")}
                </h2>
                <h3 className="term-sub-heading">
                  {t("sales.communication_means")}
                </h3>
                <p className="terms-pera mb-0">
                  {t("sales.communication_means_intro")}
                </p>
                <ul className="terms-ul">
                  <li className="terms-li">{t("sales.sms")}</li>
                  <li className="terms-li">{t("sales.emails")}</li>
                  <li className="terms-li">{t("sales.phone")}</li>
                  <li className="terms-li">{t("sales.online_messaging")}</li>
                  <li className="terms-li">
                    {t("sales.mobile_app_notification")}
                  </li>
                </ul>
                <p className="terms-pera">
                  {t("sales.communication_means_details")}
                </p>
                <p className="terms-pera mb-0">
                  {t("sales.communication_purpose_intro")}
                </p>
                <ul className="terms-ul">
                  <li className="terms-li">
                    {t("sales.appointment_confirmation")}
                  </li>
                  <li className="terms-li">{t("sales.queue_progress")}</li>
                  <li className="terms-li">{t("sales.visa_status")}</li>
                  <li className="terms-li">
                    {t("sales.payment_confirmation")}
                  </li>
                  <li className="terms-li">{t("sales.satisfaction_survey")}</li>
                </ul>
                <p className="terms-pera">
                  {t("sales.contact_info_responsibility")}
                </p>
                <p className="terms-pera">
                  {t("sales.communication_notification")}
                </p>
                <p className="terms-pera">
                  {t("sales.communication_compliance")}
                </p>
                <h3 className="term-sub-heading">
                  {t("sales.prohibited_uses")}
                </h3>
                <p className="terms-pera mb-0">
                  {t("sales.prohibited_uses_description")}
                </p>
              </div>

              <div className="terms-notes">
                <h2 className="term-heading">
                  {t("sales.article_7_recommendations")}
                </h2>
                <p className="terms-pera">
                  {t("sales.recommendation_read_requirements")}
                </p>
                <p className="terms-pera">
                  {t("sales.recommendation_timing_document_upload")}
                </p>
                <p className="terms-pera">
                  {t("sales.recommendation_partial_upload")}
                </p>
                <p className="terms-pera">
                  {t("sales.recommendation_communication_methods")}
                </p>
                <p className="terms-pera">
                  {t("sales.recommendation_document_copy")}
                </p>
              </div>

              <div className="terms-notes">
                <h2 className="term-heading">{t("sales.article_8_title")}</h2>
                <h3 className="term-sub-heading">
                  {t("sales.article_8_1_title")}
                </h3>
                <p className="terms-pera">{t("sales.section_8_1_content1")}</p>
                <p className="terms-pera">{t("sales.section_8_1_content2")}</p>
                <p className="terms-pera">{t("sales.section_8_1_content3")}</p>
                <h3 className="term-sub-heading">
                  {t("sales.article_8_2_title")}
                </h3>
                <p className="terms-pera">{t("sales.section_8_2_content1")}</p>
                <p className="terms-pera">{t("sales.section_8_2_content2")}</p>
                <h3 className="term-sub-heading">
                  {t("sales.article_8_3_title")}
                </h3>
                <p className="terms-pera">{t("sales.section_8_3_content1")}</p>
                <p className="terms-pera">{t("sales.section_8_3_content2")}</p>
                <h3 className="term-sub-heading">
                  {t("sales.article_8_4_title")}
                </h3>
                <p className="terms-pera">{t("sales.section_8_4_content1")}</p>
                <p className="terms-pera">{t("sales.section_8_4_content2")}</p>
                <p className="terms-pera">{t("sales.section_8_4_content3")}</p>
                <p className="terms-pera">{t("sales.section_8_4_content4")}</p>
                <h3 className="term-sub-heading">
                  {t("sales.article_8_5_title")}
                </h3>
                <p className="terms-pera mb-0">
                  {t("sales.section_8_5_content1")}
                </p>
                <ul className="terms-ul">
                  <li className="terms-li">
                    {t("sales.section_8_5_content2")}
                  </li>
                  <li className="terms-li">
                    {t("sales.section_8_5_content3")}
                  </li>
                </ul>
                <h3 className="term-sub-heading">
                  {t("sales.article_8_6_title")}
                </h3>
                <p className="terms-pera">{t("sales.section_8_6_content1")}</p>
                <h3 className="term-sub-heading">
                  {t("sales.article_8_7_title")}
                </h3>
                <p className="terms-pera">{t("sales.section_8_7_content1")}</p>
                <p className="terms-pera">{t("sales.section_8_7_content2")}</p>
                <h3 className="term-sub-heading">
                  {t("sales.article_8_8_title")}
                </h3>
                <p className="terms-pera">{t("sales.section_8_8_content1")}</p>
                <p className="terms-pera">{t("sales.section_8_8_content2")}</p>
                <h3 className="term-sub-heading">
                  {t("sales.article_8_9_title")}
                </h3>
                <p className="terms-pera mb-0">
                  {t("sales.section_8_9_content1")}
                </p>
                <ul className="terms-ul">
                  <li className="terms-li">
                    {t("sales.section_8_9_content2")}
                  </li>
                  <li className="terms-li">
                    {t("sales.section_8_9_content3")}
                  </li>
                  <li className="terms-li">
                    {t("sales.section_8_9_content4")}
                  </li>
                </ul>
                <p className="terms-pera">{t("sales.section_8_9_content5")}</p>
              </div>

              <div className="terms-notes">
                <h2 className="term-heading">
                  {t("sales.article_9_refund_policy")}
                </h2>
                <p className="terms-pera">{t("sales.capago_refund_policy")}</p>

                <h3 className="term-sub-heading">
                  {t("sales.service_fees_heading")}
                </h3>
                <p className="terms-pera mb-0">
                  {t("sales.service_fees_non_refundable")}
                </p>
                <p className="terms-pera mb-0">
                  {t("sales.service_fees_not_refundable_conditions")}
                </p>
                <ul className="terms-ul">
                  <li className="terms-li">
                    {t("sales.service_fees_not_refundable_conditions")}
                  </li>
                  <li className="terms-li">
                    {t("sales.service_fee_condition_1")}
                  </li>
                  <li className="terms-li">
                    {t("sales.service_fee_condition_2")}
                  </li>
                </ul>

                <h3 className="term-sub-heading">
                  {t("sales.visa_fees_heading")}
                </h3>
                <p className="terms-pera">
                  {t("sales.visa_fees_non_refundable")}
                </p>

                <h3 className="term-sub-heading">
                  {t("sales.additional_fees_heading")}
                </h3>
                <p className="terms-pera">
                  {t("sales.additional_fees_non_refundable")}
                </p>

                <h3 className="term-sub-heading">
                  {t("sales.prepayment_heading")}
                </h3>
                <p className="terms-pera">
                  {t("sales.prepayment_non_refundable")}
                </p>

                <h3 className="term-sub-heading">
                  {t("sales.appointment_reschedule_heading")}
                </h3>
                <p className="terms-pera">
                  {t("sales.appointment_reschedule_policy")}
                </p>
                <p className="terms-pera">
                  {t("sales.appointment_cancellation_no_refund")}
                </p>
              </div>

              <div className="terms-notes">
                <h2 className="term-heading">
                  {t("sales.article_10_cancellation")}
                </h2>
                <p className="terms-pera">{t("sales.cancellation_fee_paid")}</p>
                <p className="terms-pera">{t("sales.cancellation_request")}</p>
                <p className="terms-pera">{t("sales.no_refund_policy")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">
                  {t("sales.article_11_violations_indemnification")}
                </h2>
                <p className="terms-pera">
                  {t("sales.violation_acknowledgment")}
                </p>
                <p className="terms-pera">{t("sales.injunction_right")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">
                  {t("sales.article_12_intellectual_property")}
                </h2>
                <p className="terms-pera">
                  {t("sales.intellectual_property_rights")}
                </p>
                <p className="terms-pera">
                  {t("sales.reproduction_prohibition")}
                </p>
                <p className="terms-pera">
                  {t("sales.intellectual_property_info")}
                </p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">
                  {t("sales.article_13_data_protection")}
                </h2>
                <p className="terms-pera">
                  {t("sales.data_collection_intent")}
                </p>
                <p className="terms-pera">
                  {t("sales.data_processing_consent")}
                </p>
                <p className="terms-pera">
                  {t("sales.third_party_data_sharing")}
                </p>
                <p className="terms-pera">
                  {t("sales.data_protection_by_client")}
                </p>
                <p className="terms-pera">{t("sales.privacy_policy_rights")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">
                  {t("sales.article_14_applicable_law")}
                </h2>
                <p className="terms-pera">{t("sales.governing_law")}</p>
                <p className="terms-pera">
                  {t("sales.jurisdiction_acceptance")}
                </p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">
                  {t("sales.article_15_severability")}
                </h2>
                <p className="terms-pera">{t("sales.severability_clause")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">
                  {t("sales.article_16_non_renunciation")}
                </h2>
                <p className="terms-pera">{t("sales.inaction_of_capago")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">
                  {t("sales.article_17_modifications")}
                </h2>
                <p className="terms-pera">{t("sales.modification_clause")}</p>
                <p className="terms-pera">
                  {t("sales.modification_implementation")}
                </p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">
                  {t("sales.article_18_generalities")}
                </h2>
                <p className="terms-pera">{t("sales.acceptance_of_terms")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SalesTerms;
