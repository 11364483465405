import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
const CookiePolicy = () => {
   const { t } = useTranslation();
  return (
    <main className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("cookie_title")}</title>
        <meta name="description" content={t("ccookie_metaDescription")} />
        <meta name="keywords" content={t("cookie_metaKeywords")} />
      </Helmet>
      <section className="terms-group-section">
        <div className="terms-data-img">
          <div className="terms-details">
            <div>
              <h1 className="condition-h1">{t("pageTitle")}</h1>
              <p className="condition-date">{t("effectiveDate")}</p>
            </div>
            <div>
              <div className="condition-line"></div>
              <p className="condition-pera">{t("introText")}</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="innovation-radius">
          <div className="container">
            <div className="terms-details-values">
              <div className="terms-notes">
                <h2 className="term-heading">{t("sections.article1Title")}</h2>
                <p className="terms-pera">{t("sections.article1Text1")}</p>
                <p className="terms-pera">{t("sections.article1Text2")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("sections.article2Title")}</h2>
                <p className="terms-pera">{t("sections.article2Text1")}</p>
                <p className="terms-pera">{t("sections.article2Text2")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("sections.article3Title")}</h2>
                <h3 className="terms-pera">{t("sections.article3_1Title")}</h3>
                <p className="terms-pera">{t("sections.article3_1Text1")}</p>
                <p className="terms-pera">{t("sections.article3_1Text2")}</p>
                <h3 className="terms-pera">{t("sections.article3_2Title")}</h3>
                <p className="terms-pera">{t("sections.article3_2Text1")}</p>
                <p className="terms-pera">
                  <span>{t("sections.article3_2Text2_1")}</span>
                  <br />
                  {t("sections.article3_2Text2_2")}
                </p>
                <p className="terms-pera">
                  <span>{t("sections.article3_2Text3_1")}</span>
                  <br />
                  {t("sections.article3_2Text3_2")}
                </p>
                <p className="terms-pera">
                  <span>{t("sections.article3_2Text4_1")}</span>
                  <br />
                  {t("sections.article3_2Text4_2")}
                </p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("sections.article4Title")}</h2>
                <p className="terms-pera">{t("sections.article4Text1")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("sections.article5Title")}</h2>
                <p className="terms-pera">{t("sections.article5Text1")}</p>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("sections.article6Title")}</h2>
                <p className="terms-pera">{t("sections.article6Text1")}</p>
                <ul className="terms-ul">
                  <li className="terms-li">
                    {t("sections.article6Contact.address")}
                  </li>
                  <li className="terms-li">
                    {t("sections.article6Contact.phone")}
                  </li>
                  <li className="terms-li">
                    {t("sections.article6Contact.email")}
                  </li>
                </ul>
              </div>
              <div className="terms-notes">
                <h2 className="term-heading">{t("sections.article7Title")}</h2>
                <p className="terms-pera">{t("sections.article7Text1")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CookiePolicy;
