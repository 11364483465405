import React from "react";
import Expertise from "../views/solutions/Expertise";
import ServicesItems from "../components/solutions/ServicesItems";
import SolutionMission from "../views/solutions/SolutionMission";
import SecuritiesItems from "../components/solutions/SecuritiesItems";
import InnovationsItems from "../components/solutions/InnovationItems";
import { Helmet } from "react-helmet";
import SolutionsHero from "../views/solutions/SolutionsHero";
import { useTranslation } from "react-i18next";

const Solutions = () => {
  const { t } = useTranslation();
  return (
    <main className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("solutions_meta.title")}</title>
        <meta name="description" content={t("solutions_meta.description")} />
        <meta name="keywords" content={t("solutions_meta.keywords")} />
      </Helmet>
      <SolutionsHero />
      <Expertise />
      <ServicesItems />
      <SecuritiesItems />
      <SolutionMission />
      <InnovationsItems />
    </main>
  );
};

export default Solutions;
