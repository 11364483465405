import React from 'react'
import fb from "../assets/img/facebbok.svg"
import share from "../assets/img/share.svg";
import linkedin from "../assets/img/linkedin.svg";
import card1 from "../assets/img/card-1.jpeg"
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
const FutureChallenges = () => {
  const { t } = useTranslation();
  return (
    <main className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("metatitle")}</title>
        <meta name="description" content={t("description")} />
        <meta name="keywords" content={t("keywords")} />
      </Helmet>
      <section className="group-section">
        <div className="panel_animation">
          <div className="main-group-img future-grp-img">
            <div className="future-challange">
              <div className="challanges-details">
                <div className="back-button">
                  <button className="arrow-btn">
                    <i className="bi bi-arrow-left"></i>
                  </button>
                  <p className="back-text">{t("challenges.p1")}</p>
                </div>
                <div className="challange-text">
                  <div className="divider-line"></div>
                  <p>{t("challenges.p2")}</p>
                </div>
                <h2 className="future-h2">{t("challenges.title")}</h2>
                <div className="services-button">
                  <button className="service-btn">{t("challenges.btn")}</button>
                  <button className="service-btn">{t("challenges.btn")}</button>
                </div>
                <p className="future-pera">
                  <i className="bi bi-clock-history"></i> {t("challenges.p3")}
                </p>
                <p className="future-pera">{t("challenges.p4")} </p>
              </div>
              <div className="social-share-btns">
                <button className="soc-btn">
                  <img src={share} alt="share" />
                  {t("challenges.share")}
                </button>
                <button className="soc-btn">
                  <img src={fb} alt="share" />
                </button>
                <button className="soc-btn">
                  <img src={linkedin} alt="share" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="artical-data-values">
            <div className="artical-links">
              <p className="list-name-icon">
                <i className="bi bi-list-ul"></i>
                {t("challenges.p5")}{" "}
              </p>
              <div className="black-divider"></div>
              <ul className="tag-ul">
                <li className="tag-li">
                  {" "}
                  <a href="/">{t("challenges.p6")}</a>
                </li>
                <li className="tag-li">
                  {" "}
                  <a href="/">{t("challenges.p7")}</a>
                </li>
              </ul>
            </div>
            <div className="artical-detail">
              <p className="artical-main-pera">{t("challenges.p8")}</p>
              <div className="artical-data">
                <h3 className="artical-h3">{t("challenges.p9")}</h3>
                <p className="artical-pera">{t("challenges.p10")}</p>
                <h4 className="artical-h4">{t("challenges.p11")}</h4>
                <p className="artical-pera">{t("challenges.p12")}</p>
                <p className="artical-pera"> {t("challenges.p13")}</p>
                <p className="artical-pera">{t("challenges.p14")}</p>
                <p className="artical-pera">{t("challenges.p15")}</p>
              </div>
              <div className="artical-data">
                <h3 className="artical-h3">{t("challenges.p16")}</h3>
                <p className="artical-pera">{t("challenges.p17")}</p>
                <p className="artical-pera">{t("challenges.p18")}</p>
                <p className="artical-pera">{t("challenges.p19")}</p>
                <p className="artical-pera"> {t("challenges.p20")}</p>
                <p className="artical-pera"> {t("challenges.p21")}</p>
                <p className="artical-pera">{t("challenges.p22")}</p>
                <p className="artical-pera">{t("challenges.p23")}</p>
                <p className="artical-pera">{t("challenges.p24")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <secion id="future">
        <div className="future">
          <div className="container">
            <div className="find-data-cards">
              <h2 className="find-text">{t("challenges.text")}</h2>
              <div className="future-cards">
                <div className="slider-card">
                  <div className="card-img-part">
                    <img src={card1} alt="" />
                    <button className="card-direction-btn">
                      <i className="bi bi-arrow-up-right"></i>
                    </button>
                  </div>
                  <div className="slider-card-details">
                    <h4 className="card-heading">
                      {t("challenges.slider_title")}
                    </h4>
                    <p className="card-pera">{t("challenges.slider_desc")}</p>
                  </div>
                  <div className="card-buttons">
                    <button className="ser-btn">{t("challenges.btn")}</button>
                    <button className="ser-btn">{t("challenges.btn")}</button>
                  </div>
                </div>
                <div className="slider-card">
                  <div className="card-img-part">
                    <img src={card1} alt="" />
                    <button className="card-direction-btn">
                      <i className="bi bi-arrow-up-right"></i>
                    </button>
                  </div>
                  <div className="slider-card-details">
                    <h4 className="card-heading">
                      {t("challenges.slider_title")}
                    </h4>
                    <p className="card-pera">{t("challenges.slider_desc")}</p>
                  </div>
                  <div className="card-buttons">
                    <button className="ser-btn">{t("challenges.btn")}</button>
                    <button className="ser-btn">{t("challenges.btn")}</button>
                  </div>
                </div>
                <div className="slider-card">
                  <div className="card-img-part">
                    <img src={card1} alt="" />
                    <button className="card-direction-btn">
                      <i className="bi bi-arrow-up-right"></i>
                    </button>
                  </div>
                  <div className="slider-card-details">
                    <h4 className="card-heading">
                      {t("challenges.slider_title")}
                    </h4>
                    <p className="card-pera">{t("challenges.slider_desc")}</p>
                  </div>
                  <div className="card-buttons">
                    <button className="ser-btn">{t("challenges.btn")}</button>
                    <button className="ser-btn">{t("challenges.btn")}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </secion>
    </main>
  );
}

export default FutureChallenges
